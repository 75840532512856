import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import {
} from "@material-ui/core";
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
    root: () => ({
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'scroll',
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontFamily: 'Kanit',
    }),
    topBar: () => ({
        position: 'sticky',
        top: "0px",
        width: "100%",
        backgroundColor: theme.palette.primary.light,
    }),
    header: () => ({
        backgroundColor: 'rgb(65, 118, 181)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '3rem',
        width: '35rem',
        height: '25vh',
        textAlign: 'center',
        // borderRadius: '15px 15px 0 0',
        color: 'white',
        boxShadow: `4px 0px 4px${theme.palette.secondary.main}`,
        flexWrap: 'wrap',
        padding: '2rem'
    }),
    info: () => ({
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20rem',
        height: '25vh',
        boxShadow: `4px 0px 4px${theme.palette.secondary.main}`,
        flexWrap: 'wrap',
        padding: '2rem',
        fontSize: '1.5rem'
        // borderRadius: '0 0 15px 15px',

    })
}));

export default function Contact() {
    const classes = useStyles();
    return (
        //Big container
        <div className={classes.root}>
            {/* Multi-screen landing pages */}
            <div className={classes.header}>
                <img src="/magLabPhoto.png" style={{width: '12rem', marginRight: '0.5rem'}}/>
                Contact Us
            </div>
            <div className={classes.info}>
                <img src="/email.png" alt="email icon"style={{width: '25%'}} />
                <p style={{marginTop: '-0.1rem', fontSize: '1.5rem'}}>EMAIL</p>
                mmoldwin@umich.edu
            </div>
        </div>
    )
}