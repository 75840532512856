import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import{
    Button,
    Typography,
} from "@material-ui/core";
import { history } from '../redux';

const useStyles = makeStyles((theme) => ({
    root: ({ spacingWidth }) => ({
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        zIndex: 100,
        boxShadow: "0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1)",
        background: 'white',
        justifyContent: "flex-end",
    }),
    // mainButton: ({ mainButtonWidth }) => ({
    //     display: 'flex',
    //     width: `${mainButtonWidth}%`,
    //     justifyContent: "flex-start",
    //     marginLeft: theme.spacing(8)
    // }),
    otherHeaders: ({ mainButtonWidth }) => ({
        display: 'flex',
        width: `${100 - mainButtonWidth}%`,
        justifyContent: "flex-end",
        marginRight: '2rem',
    }),
    logo: ({ mainButtonWidth }) => ({
        display: 'flex',
        width: `4.2rem`,
        justifyContent: "flex-start",
    })
}));

export default function NavBar() {
    const styleConstants = {
        mainButtonWidth: 20,
        spacingWidth: 8,
    }
    const classes = useStyles(styleConstants);
    return (
        <div className={classes.root}>
          
          
            {/* <div className={classes.mainButton}>
                {/* <Button onClick={() => history.push('/')} >
                    <Typography variant="overline">
                        <strong>
                            Mag Lab
                        </strong>
                    </Typography>
                </Button>
            <img alt="MagLab logo" className={classes.logo} 
            src="/magLabPhoto.png" onClick={() => history.push('/')}
            style={{cursor:'pointer'}}></img>
        </div> 
        */}
   
            <div className={classes.otherHeaders}>
                <Button onClick={() => history.push('/home')}>
                    <Typography variant="overline" sx={{fontWeight: '600', fontSize: '20'}}>
                        Home
                    </Typography>
                </Button>
                <Button onClick={() => history.push('/maps')}>
                    <Typography variant="overline">
                        Map
                    </Typography>
                </Button>
                <Button onClick={() => history.push('/gallery')}>
                    <Typography variant="overline">
                        Images
                    </Typography>
                </Button>
                <Button onClick={() => history.push('/contact')}>
                    <Typography variant="overline">
                        Contact
                    </Typography>
                </Button>
                {/* <Button onClick={() => history.push('/calendar')}>
                    <Typography variant="overline">
                        Calendar
                    </Typography>
                </Button> */}
                {/* <Button>
                    <Typography variant="overline">
                        Sign up
                    </Typography>
                </Button> */}
            </div>
        </div>
    )
}