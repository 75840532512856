import React from "react";

import YearCalendar from 'rc-year-calendar';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

import './styles.css';
import './calendar.css'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import magData from '../redux/magData/magData';
import { GRADIENT } from '../../AppV2';
import { API_URL } from "../redux/apis/apiClient";

import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
  } from "@mui/material";
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import LocationIcon from '@mui/icons-material/FmdGood';

// ---------------------------------------------------------
//                          Styles
// ---------------------------------------------------------

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      // overflowY: 'scroll',
      marginBottom: '5vh',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '0.7rem'
    },
    legendRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '0.6rem'
  },
   colorBox: {
        display: 'flex',
        alignSelf: 'center',
        height: theme.spacing(3),
        width: theme.spacing(4),
    },
    calendar: {
      marginBottom: '10vh',
      background: 'white'
    },


}));


// ---------------------------------------------------------
//                     Calendar Component
// ---------------------------------------------------------

function RCCalendar(props){
    const classes = useStyles();
    const [availableDates, setAvailableDates] = React.useState([]);
    const [startDate, setStartDate] = React.useState(dayjs(new Date()));
    const [endDate, setEndDate] = React.useState(dayjs(new Date()));
    const [formattedStart, setFormattedStart] = React.useState(startDate.format('MM-DD-YYYY'));
    const [formattedEnd, setFormattedEnd] = React.useState(endDate.format('MM-DD-YYYY'));

    // Retrieve the dates with date for this sensor
    React.useEffect(() => {
        fetch(API_URL+'/api/availableDates')
        .then(res => res.json())
        .then(data => setAvailableDates(data[props.sensorId]["dateArray"]));
      }, [])



// -----------------------------------------------------------------
//                      Year Calendar Functions
// -----------------------------------------------------------------
    function getColor(fileSize){
    let color;

      if(fileSize < 10){
          color = GRADIENT[0]
      }
      else if(10 <= fileSize && fileSize < 25){
          color = GRADIENT[1]
      }
      else if(25 <= fileSize && fileSize < 50){ 
          color = GRADIENT[2]
      }
      else if(50 <= fileSize && fileSize < 75){
          color = GRADIENT[3]
      }
      else if(75 <= fileSize && fileSize < 100){
          color = GRADIENT[4]
      }
      else if(100 <= fileSize && fileSize < 150){
          color = GRADIENT[5]
      }
      else if(150 <= fileSize && fileSize < 200){
          color = GRADIENT[6]
      }
      else{
          color = GRADIENT[9]
      }
  return color;

  }

    function formatDataSource(){
      let events = [];
      for(let i = 0; i < availableDates.length; ++i){
          const date = availableDates[i][`date`];
          const fileSize = availableDates[i][`size`]
          // 06-14-2023
          let month = Number(date.slice(0,2)) - 1;
          let day = Number(date.slice(3,5));
          let year = Number(date.slice(6));
  
          events.push(
              {
                  id: i,
                  name: 'Data',
                  startDate: new Date(year, month, day),
                  endDate: new Date(year, month, day),
                  color: getColor(fileSize),
                  fileSize: fileSize
              }
          )
      }
      return events;
  }

    function handleRangeSelected(e){
      setFormattedStart(dayjs(e.startDate).format('MM-DD-YYYY'));
      setFormattedEnd(dayjs(e.endDate).format('MM-DD-YYYY'))
      setStartDate(dayjs(e.startDate))
      setEndDate(dayjs(e.endDate))
  }


// -----------------------------------------------------------------
//                             Header Cards
// -----------------------------------------------------------------

  const rangeDisplayCard = (
    <React.Fragment>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{marginTop: '2vh'}}>
                  <DateField
                    defaultValue={dayjs(new Date())}
                    label="Start Date"
                    value={startDate}
                    onChange={(newVal) => {setStartDate(newVal)
                                          setFormattedStart(newVal.format('MM-DD-YYYY'))}}
                  />
                  <DateField
                    defaultValue={dayjs(new Date())}
                    label="End Date"
                    value={endDate}
                    onChange={(newVal) => {setEndDate(newVal)
                                            setFormattedEnd(newVal.format('MM-DD-YYYY'))}}
                  />
                </div>
            </LocalizationProvider>
      </CardContent>
      <CardActions className={classes.buttons}>
        
        <Button variant="contained" className={classes.button} onClick={() => {
          props.triggerDownloadData({ID: props.sensorId, startDate: formattedStart, endDate: formattedEnd, type: "pni"})}}>
            pni
          </Button>
  
        <Button variant="contained" className={classes.button} onClick={() => {
          props.triggerDownloadData({ID: props.sensorId, startDate: formattedStart, endDate: formattedEnd, type: "gps"})}}>
            gps
          </Button>
                  
        <Button variant="contained" className={classes.button} onClick={() => {
          props.triggerDownloadData({ID: props.sensorId, startDate: formattedStart, endDate: formattedEnd, type: "temp"})}}>
            temp
          </Button>
                  
        <Button variant="contained" className={classes.button} color='secondary' onClick={() => {
          props.triggerDownloadData({ID: props.sensorId, startDate: formattedStart, endDate: formattedEnd, type: 'all'})}}>
            all
        </Button>
        
      </CardActions>
    </React.Fragment>
  );
      
  function RangeDisplayCard() {
    return (
      <Box sx={
        { minWidth: '30vw', textAlign: 'center', marginLeft: '2rem', marginRight: '2rem'}
        // marginLeft: '20%', marginBottom: '10vh', marginTop: '2vh'
        }>
        <Card variant>{rangeDisplayCard}</Card>
      </Box>
    );
  }

  function Legend() {
    const classes = useStyles();
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography style={{ fontSize: 15, marginBottom: '0.1rem'}}>
            PNI + GPS + TEMP file size: 
        </Typography>
        <div className={classes.legendRoot} >
            <Typography style={{ fontSize: 13, marginRight: '2px', marginBottom: '0.5rem'}}>
                {'<'}10 MB
            </Typography>
            {GRADIENT.map((val, ind) => { return <div key={ind} style={{ background: val }} className={classes.colorBox} /> })}
            <Typography style={{ fontSize: 13, marginLeft: '2px' }}>
            {'>'}200 MB
            </Typography>
        </div> 
        <div className="PlotButton">
          <Typography sx={{fontSize:14, marginRight: '1rem', color:"text.secondary", textAlign: 'center', marginBottom: '0.5rem'}}>
                Click button to view a plot of PNI data for the start date in selected range. Confirm pop-up is blocker disabled.
          </Typography>
          <div className={classes.buttons}>
          <Button variant="contained" className={classes.button} color='secondary' onClick={() => {
          props.triggerViewPlot({ID: props.sensorId, date: formattedStart})}}>
            view pni plot for {formattedStart}
          </Button></div>

        </div>
      </div>
    )
  }

  const userIdCard = (
    <React.Fragment>
      <CardContent sx={{display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '16rem',
                    paddingTop: '2 0 0 2'}}>
            <Typography sx={{fontSize:25, marginRight: '1rem', marginLeft: '-1.5rem'}}> <LocationIcon fontSize='small'/>{props.sensorId}</Typography>
            <Typography sx={{fontSize:'1.1rem', marginRight: '1rem'}}>{props.location}</Typography>
            <Typography sx={{fontSize:14, marginRight: '1rem', color:"text.secondary", textAlign: 'center'}}>
              Hold and drag cursor over calendar days or type dates into field to select data range. Click button to download. Disable pop-up blocker if downloading data or viewing plot.
            </Typography>
        </CardContent>
    </React.Fragment>
  );
      
  function UserIdCard() {
    return (
      <Box sx={
        { maxWidth: '20rem', minWidth: '22rem'}
        }>
        <Card variant>{userIdCard}</Card>
      </Box>
    );
  }

  const legendCard = (
    <React.Fragment>
      <CardContent sx={{display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
            <Legend/>
        </CardContent>
    </React.Fragment>
  );
    
  function LegendCard() {
    return (
      <Box sx={
        { maxWidth: '25rem'}
        }>
        <Card variant>{legendCard}</Card>
      </Box>
    );
  }

// -----------------------------------------------------------------
//                      Return: Header, YearCalendar
// -----------------------------------------------------------------

    return(
        <div className={classes.root}>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                          flexWrap: 'wrap', marginBottom: '1.5rem', backgroundColor: 'white', width: '90vw', borderRadius: '5px'}}>
              <UserIdCard/>
              <RangeDisplayCard/>
              <LegendCard/>
            </div>
            
            <YearCalendar
                enableRangeSelection="true"
                dataSource={formatDataSource()}       
                onRangeSelected={handleRangeSelected}
                style="custom"
                customDataSourceRenderer={(element, currentDate, events) => {
                  let fileSize = 0;
                  for(let i = 0; i < events.length; ++i){
                      fileSize += events[i].fileSize;
                    }
                    element.style.boxShadow = `${getColor(fileSize)} 0px -0.5rem 0 0 inset`;
                }}

                // customDataSourceRenderer={(element, currentDate, events) => {
                //     element.parentElement.style.background = `${events[0].color}`;
                //     element.style.color = `#FAF9F6`;
                //     element.style.fontWeight = `bold`;
                //     element.style.webkitTextStroke = `0.45px black`;
                // }}     
                
            />

        </div>
    )
}

/* 
Make action creators (triggerDownloadData and triggerViewPlot) available as props in RCCalendar component. 
Allows the component to dispatch actions directly by calling triggerDownloadData and triggerViewPlot
*/
export default connect(
    (state) => ({
        magData: state.magData.individualMagData,
    }),
    (dispatch) =>
    bindActionCreators(
      {
        triggerDownloadData: magData.actions.triggerDownloadData,
        triggerViewPlot: magData.actions.triggerViewPlot,
      },
      dispatch
    )
)(RCCalendar);