import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ImgMediaCard(props) {
  return (
    <Card sx={{ maxWidth: '25rem', borderRadius: 0.5}}>
      <CardMedia
        component="img"
        alt={props.alt}
        height="400"
        image={props.img}
      />
      <CardContent>
        <Typography gutterBottom variant="body2" component="div">
          {props.label}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography> */}
      </CardContent>
    </Card>
  );
}