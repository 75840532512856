import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: () => ({
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        marginBottom: '10vh'
    }),
    section: () => ({
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: "90%",
        minHeight: '25vh',
        backgroundColor: '#4176B5',
        marginBottom: '2vh',
        boxShadow: `4px 0px 4px${theme.palette.secondary.main}`,
        padding: '1rem'

    }),
    text: () => ({
        textAlign: 'center',
        width: '75%',
        fontSize: '1.4rem',
        fontWeight: '330',
    }),
    img: () => ({
        textAlign: 'center',
        // width: '30%',
        height: '23vh',
    }),

}));


export default function MissionPage() {
    const styleConstants = {
        textBoxWidth: "50%",
        imageContainerWidth: "50%",
    }
    const classes = useStyles(styleConstants);
    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.text} style={{color:'white'}}>
                To contribute to the study of space weather and its impacts, we are working to develop a low-cost, low-power, easy to deploy ground-based magnetometer system. This is a proto-type for a potential commercial system that would enable global coverage.
                </div>
                <img className={classes.img} src="/sun2.png"/>         
            </div>

            <div className={classes.section} style={{backgroundColor:'white'}}>
            <img className={classes.img} src="/earth.png"/>  
                <div className={classes.text} style={{color:'black'}}>
                Solar disturbances like CMEs and solar flares can impact technological systems like satellite navigation and communication, power grids, and high-frequency radio communication. Space weather effects vary based on latitude, longitude, and local time, but a lack of ground instrumentation means these effects are not yet well-studied.
                </div>
            </div>

            <div className={classes.section}>
                <div className={classes.text} style={{color:'white'}}>
                Our magneto-inductive magnetometer uses a modified-commercial PNI system for low-cost, low-power, and small-size. The electronics, including the PNI magnetometer and GPS receiver, are packaged into a self-contained weather-proof enclosure which will limit the cost and complexity of deployment. Power options include solar panels and battery system or a standard AC power connector, and communication options are WiFi or cellular. 
                </div>
                <img className={classes.img} style={{height: '20vh'}} src="/wifi.png"/>         
            </div>
        </div>
    )
}