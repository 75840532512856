import { createSlice } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import { from, merge, of } from "rxjs";
import api from "../apis/apiClient";
import { allDataFormatter } from "../util/util";
import fileDownload from 'js-file-download'

const magData = createSlice({
    reducers: {
        triggerIndividualData: (state, { sensorId }) => {
            state.individualSensorId = sensorId;
        },

        triggerAllData: () => {
        },

        triggerDownloadData: (state, { sensorId }) => {
            state.individualSensorId = sensorId;
        },

        triggerViewPlot: (state, { sensorId }) => {
            state.individualSensorId = sensorId;
        },

        setAllData: (state, { payload }) => {
            state.allData = allDataFormatter(payload, true);
        },

        setIndividualMagData: (state, { payload }) => {
            state.individualMagData = allDataFormatter(payload, true);
        },

        setDataReceived: (state, { payload }) => {
            console.log('payload data received', payload);
            state.dataReceived = payload;
        },

    },
    initialState: {
        allData: [],
        individualMagData: [],
        individualSensorId: 0,
        numSensors: 0,
        dataReceived: false,
    },
    name: "magData",
    slice: "magData",
});

export default magData;

/* 
Epic: listens for triggerAllData action. Sets "markers" for available sensors from backend to populate map. 
data contains sensor id, username, lat/long, and filesize (with filesize as 'pniFilename')
*/
export const getAllData = (action$, state$) =>
    action$.pipe(
        ofType(magData.actions.triggerAllData),
        withLatestFrom(state$),
        mergeMap(() =>
            from(api.get(`/api/newData`)).pipe(
                mergeMap(res => {
                    return merge(
                        //returns sensor data in a json object
                        // {sensor1: {}, sensor2: {}...}
                        of(
                            magData.actions.setAllData(res.data)
                        ),
                    );
                })
            )
        )
    );


/* Currently unused */
export const getIndividualData = (action$, state$) =>
    action$.pipe(
        ofType(magData.actions.triggerIndividualData),
        withLatestFrom(state$),
        mergeMap(([{ payload }]) => {
            console.log(payload)
            let sensorId = payload;
            return from(api.get(`/api/magData?sensorId=${sensorId}`)).pipe(
                mergeMap(res => {
                    return merge(
                        //returns sensor data in a json object
                        // {sensor1: {}, sensor2: {}...}
                        of(
                            magData.actions.setIndividualMagData(res.data)
                        ),
                    );
                })
            )
        })
    );


/* 
Epic: listens for triggerDownloadData action, fetches downloadable data (as a list of links) from backend
based on sensorId, startDate, endDate, and type, and opens the data links in new browser tabs.
*/
export const getLatestDownloadData = (action$, state$) =>
    action$.pipe(
        ofType(magData.actions.triggerDownloadData),
        withLatestFrom(state$),
        mergeMap(([{ payload }]) => {
            console.log(payload)
            let sensorId = payload.ID;
            let startDate = payload.startDate;
            let endDate = payload.endDate;
            let type = payload.type;
            
            if(type === 'all'){
                return from(api.get(`/api/downloadRangeAll?sensorID=${sensorId}&dateStart=${startDate}&dateEnd=${endDate}`)).pipe(
                    mergeMap(res => {
                        for(let i = 0; i < res.data.length; i++){
                            // Open the dropbox filepath links
                            window.open(res.data[i], "_blank");
                        }
                    })
                )
            }

            else{
            return from(api.get(`/api/downloadRange?sensorID=${sensorId}&dataType=${type}&dateStart=${startDate}&dateEnd=${endDate}`)).pipe(
                mergeMap(res => {
                    for(let i = 0; i < res.data.length; i++){
                        window.open(res.data[i], "_blank");
                    }
                })
            )

        }
        })
    );

    /* 
    Epic: listens for triggerViewPlot action. Downloads plot from dropbox (unfinished).
    */
    export const getViewPlot = (action$, state$) =>
    action$.pipe(
        ofType(magData.actions.triggerViewPlot),
        withLatestFrom(state$),
        mergeMap(([{ payload }]) => {
            console.log(payload)
            let sensorID = payload.ID;
            let date = payload.date;

            return from(api.get(`/api/viewPlot?sensorID=Kinngait&date=08-14-2023`)).pipe(
                mergeMap(res => {
                        console.log(res.data[0])
                        for(let i = 0; i < res.data.length; i++){
                            window.open(res.data[i], "_blank");
                        }
                })
            )
        })
    );