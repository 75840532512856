import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import {
} from "@material-ui/core";
import ImageCard from "./ImageCard"

// Images
// import smallBox from "./images/smallBox.jpg"
// import systemBox from "./images/systemBox.jpg"
// import teddySetup from "./images/teddySetup.jpg"
// import battery from "./images/battery.jpg"
// import insideBox from "./images/insideBox.jpg"
import enclosure1 from "./images/enclosure1.jpg"
import enclosure2 from "./images/enclosure2.jpg"
import gpsAntenna from "./images/gps-antenna.jpg"
import internal from "./images/internal-components.jpg"
import outdoorSetup from "./images/outdoor-system-setup.jpg"
import sensorBox from "./images/sensor-box.jpg"
import solarPanel from "./images/solar-panel.jpg"

const useStyles = makeStyles((theme) => ({
    root: () => ({
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        backgroundColor: theme.palette.primary.main,
    }),
    header: () => ({
       fontFamily: 'Kanit',
       fontSize: '2rem',
       textAlign: 'center',
       color: 'white',
       margin: '1rem',
    }),
    gridContainer: () => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gridTemplateRows: 'repeat(3, 1fr)',
        gridColumnGap: '15px',
        gridRowGap: '15px',
        padding: '15px'
    }),
    gridItem: () => ({
        
    })
}));

export default function Contact() {
    const classes = useStyles();
    const images = [
        {img: outdoorSetup, desc: 'Outdoor ground mag system setup'}, 
        {img: internal, desc: 'Internal components: The system uses a recycled sealed lead-acid battery with an MPPT charger. A Raspberry Pi handles data collection and transmission, and a custom-designed PCB controls the power supply to the Raspberry Pi.'}, 
        {img: enclosure1, desc: 'Device enclosure'}, 
        {img: enclosure2, desc: 'Device enclosure'}, 
        {img: sensorBox, desc: 'PNI/temp sensor box'}, 
        {img: solarPanel, desc: 'Solar PV panel'},
        {img: gpsAntenna, desc: 'GPS antenna'}, ]
    return (
        //Big container
        <div className={classes.root}>
            {/* Multi-screen landing pages */}
            <div className={classes.header}>
                Photo Gallery
            </div>

            <div className={classes.gridContainer}>
                

            {images.map((image, index) => (
                <ImageCard 
                key={index} 
                img={image.img} 
                alt={`Image ${index + 1}`}
                className={classes.gridItem} 
                label={image.desc}
                description="...Ground Mag 2.4..." />
            ))}


                {/* <ImageCard
                className={classes.gridItem} 
                img={enclosure2}
                label="Main Box"
                description="...Ground Mag 2.4..."
                />

                <ImageCard
                className={classes.gridItem} 
                img={teddySetup}
                label="Setup"
                description="...Ground Mag 2.4..."
                />

                <ImageCard
                className={classes.gridItem} 
                img={battery}
                label="Example"
                description="...Ground Mag 2.4..."
                />

                <ImageCard
                className={classes.gridItem} 
                img={insideBox}
                label="Example"
                description="...Ground Mag 2.4..."
                />

                <ImageCard
                className={classes.gridItem} 
                img={circuit}
                label="Example"
                description="...Ground Mag 2.4..."
                />

                <ImageCard
                className={classes.gridItem} 
                img={teddySetup}
                label="Example"
                description="This is an example card"
                />  

                <ImageCard
                className={classes.gridItem} 
                img={teddySetup}
                label="Example"
                description="This is an example card"
                /> */}


            </div>
        </div>
    )
}