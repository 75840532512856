import React, { useEffect } from 'react';
import RCCalendar from './Calendar';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    IconButton,
    Card,
    CardContent
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {GRADIENT} from '../../AppV2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { history } from '../redux';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // background: '#F0F0F0',
        background: theme.palette.primary.main,
        overflowY: 'scroll',
    },
    calendarBox: {
        width: "90%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '3vh',
    },
}));


export default function CalendarContainer() {
    const classes = useStyles();
    
    const info = useLocation();
    const ID = info.state.id;
    const loc = info.state.loc;


    return (
        <div className={classes.root}>
            <IconButton sx={{alignSelf: 'flex-start', marginLeft: '2rem', marginTop: '2vh', color: 'white'}} onClick={() => {
                history.push("/maps")}} ><ArrowBackIcon/>
            </IconButton>
                <div className={classes.calendarBox}>
                    <RCCalendar sensorId={ID} location={loc}/>
                </div>  
        </div>
    )
}