import axios from "axios";


//This is where you define where the backend is hosted. For testing, it's hosted locally. For deployment, it's on the deployment variable.
const local = 'http://localhost:8000';
const deploy = 'https://backend-dot-groundmagproject-3ba3.uk.r.appspot.com'

export const API_URL = deploy;  // !! update when developing/deploying :)

export default axios.create({
  baseURL: API_URL,
//   withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrftoken",
  validateStatus: () => true,
  // headers: {
  //   Accept: "application/json",
  // },
});