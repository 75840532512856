import React, { useEffect } from 'react';
import Map from './Map';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
} from "@material-ui/core";
import Legend from './Legend';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        overflowY: 'scroll',
    },
    aboveMap: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        justifyContent: 'center',
        color: "white",
        fontFamily: 'Kanit',
        textAlign: 'center',
        marginBottom: '1rem',
    },
    legend: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    topBar: () => ({
        position: 'sticky',
        top: "0px",
        width: "100%",
        backgroundColor: theme.palette.primary.light,
    }),
}));

export default function MapContainer({ setDataReceived }) {
    useEffect(() => {
        setDataReceived(true);
    }, []);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.aboveMap}>
                    <div style={{fontSize: '2rem'}}>Global Magnetometers</div>
                    <div style={{fontSize: '1rem'}}>Use the map to select a sensor. You will be given the option to redirect to a calendar where you can download its PNI, GPS, and/or Temp data.</div>
            </div>
           
                <Map />
                <div className={classes.legend}>
                    <Legend />
                </div>
        </div>
    )
}