/* global google */
import React, { useState, useEffect } from 'react';
import { API_URL } from '../redux/apis/apiClient';
import GoogleMapReact from 'google-map-react';
import "./styles.css";
import Marker from './Marker';
import {
  Card,
  CardContent,
  Typography,
  Slide,
  IconButton,
  Button,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import GRADIENT from '../../AppV2';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import magData from '../redux/magData/magData';
import { history } from '../redux';


const useStyles = makeStyles(theme => ({
  closeButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: "flex-start",
  },
}));


function Map(props) {
  let mapGlobal, heatmap;
  const [heatmapOn, setHeatmapOn] = useState(true);
  const [checked, setChecked] = useState(false);
  const [currMarkerId, setCurrMarkerId] = useState();
  const [currMarkerName, setCurrMarkerName] = useState();
  //plotPoints - {lat, long, weight}

  useEffect(() => {
    props.triggerAllData();
  }, []);

  const setOpenMarker = (markerId, markerName) => {
    if (markerId === currMarkerId && checked) {
      setChecked(false);
    }
    else {
      setChecked(true);
      setCurrMarkerId(markerId);
      setCurrMarkerName(markerName);
    }
  }

  // Get the info for the marker card
  const LatestData = () => {
    const [fileSize, setFileSize] = React.useState();
    const [availableDates, setAvailableDates] = React.useState([]);
    let datesWithData = [];

    console.log(`__DUBUG__: API URL = ${API_URL}`)

    React.useEffect(() => {
      fetch(API_URL+'/api/availableDates')
      .then(res => res.json())
      .then(data => {
        setAvailableDates(data[currMarkerId]["dateArray"])
        setFileSize(data[currMarkerId]["pastMonthSize"])
      })  
    }, [])

    // Get an array of Date objects
    for(let i = 0; i < availableDates.length; i++){

      let monthIdx = availableDates[i].date.slice(0,2);
      if(monthIdx[0] === '0') monthIdx = monthIdx[1];
      monthIdx = Number(monthIdx) - 1;
      let day = availableDates[i].date.slice(3,5)
      if(day[0] === '0') day = day[1];
      day = Number(day);
      let year = availableDates[i].date.slice(6);

      const currDate = new Date(year, monthIdx, day);
      let string_dates = datesWithData.map(x => x.toString());
      if(!(string_dates.includes(currDate.toString()))){
        datesWithData.push(currDate)
      }
    }

    // descending order
    datesWithData.sort((date1, date2) => date2 - date1);

    const stop = new Date()
    stop.setDate(stop.getDate() - 30);

    let count = 0;
    for(let d = 0; d < 30; d++){
      if(datesWithData[d] > stop) count++;
    }
    
    const fileSizeGB = fileSize ? Math.round(fileSize/10)/100 : 0;
    return(
      <>
      <Typography sx={{color: '#1976d2'}}> {count} {count === 1 ? 'day' : 'days'} with data in the last month &#40;{(fileSizeGB)} GB&#41; </Typography>
      </>
    )
  }

  const MarkerCard = () => {
    const classes = useStyles();
    return (
      <Slide direction="right" in={checked} container={mapGlobal} mountOnEnter unmountOnExit>
        
        <Card variant='outlined' 
              sx={{zIndex: 10, width: '40rem', height: '22rem', marginRight: '5rem', 
                   display: 'flex', flexDirection: 'column', overflowY: 'scroll', overflowX: 'scroll'}}>
              
              <div style={{display:'flex', justifyContent: 'flex-end', marginTop: '0.5rem', 
                           marginRight: '0.5rem', marginBottom: '-1rem'}}>
                <IconButton
                onClick={() => setChecked(false)} size="small" color=''><CloseIcon />
                </IconButton>
              </div>

          <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', paddingBottom: '0'}}>
            <Typography>Location: </Typography>
            <Typography sx={{ fontSize: 20, color: '#1976d2'}}>{currMarkerName}</Typography>
          </CardContent>

          <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', paddingBottom: '0', paddingTop: '0.5rem'}}>
            <Typography>Station ID: </Typography>
            <Typography sx={{ fontSize: 20, color: '#1976d2'}}>{currMarkerId}</Typography>
          </CardContent>

          <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
            <Typography>Latest Data: </Typography>
            <LatestData/>
          </CardContent> 

          <CardContent>
            <Button variant="contained" startIcon={<CalendarMonthIcon/>}
            sx={{width: '100%', height: '150%', margin: '0', fontSize:'1rem', alignSelf: 'flex-end',}} 
            onClick={() => {
                  // props.triggerIndividualData(currMarkerId);
                  history.push({
                    pathname: "/calendar",
                    state: {id: currMarkerId, loc: currMarkerName}
                  });
                }}>
                  download from calendar
              </Button>
          </CardContent>
    </Card>
  </Slide>
    )
  }

  return (
    <div className="App">
      {/* <Button variant="outlined" onClick={() => {
        setHeatmapOn(!heatmapOn);
        heatmap.set(mapGlobal);
      }}
      >
        Toggle Heatmap
      </Button> */}
      <MarkerCard />
      <GoogleMapReact
        gradient={GRADIENT}
        ref={(el) => mapGlobal = el}
        bootstrapURLKeys={{
          // remove the key if you want to fork
          key: "AIzaSyC99o1QEigKpPaGQimzKsM3w6bbotM3CL4",
          language: "en",
          region: "US",
          libraries: 'visualization',
        }}
        defaultCenter={{ lat: 42.2808, lng: -83.732124 }}
        defaultZoom={3}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) => {
        //   heatmap = new maps.visualization.HeatmapLayer({
        //     data: points.map(point => (
        //       {
        //         location: new maps.LatLng(point['lat'], point['lng']),
        //         weight: point['weight'],

        //       }))
        //   });
        //   heatmap.set("gradient", gradient);
        //   mapGlobal = map;
        //   heatmap.setMap(map);
        // }}
        options={map => ({
          streetViewControl: true,
          draggable: true, // make map draggable
          zoomControlOptions: { position: 9 },
          keyboardShortcuts: true, // keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          mapTypeId: map.MapTypeId.ROADMAP, //default map style
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: map.MapTypeControlStyle.HORIZONTAL_BAR, //style of map control
            position: map.ControlPosition.TOP_LEFT, //location of map type controls
            mapTypeIds: [
              map.MapTypeId.ROADMAP,
              map.MapTypeId.TERRAIN, //roadmap w/ terrain illustrations
              map.MapTypeId.SATELLITE, //pure satellite
              map.MapTypeId.HYBRID //satellite w/ roads
            ]
          },
        })}
      >
        {props.allData.map(({ lat, long, username, id, pniFilename }) => {
          console.log(id, username);
          console.log('file',pniFilename)
          return (
            <Marker key={id} lat={lat} lng={long} markerId={id} title={username} weight={pniFilename} openInfo={setOpenMarker} />
          );
        })}
      </GoogleMapReact>
    </div >
  )
}

export default connect(
  (state) => ({
    allData: state.magData.allData,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        triggerAllData: magData.actions.triggerAllData,
        triggerIndividualData: magData.actions.triggerIndividualData,
        triggerDownloadData: magData.actions.triggerDownloadData,
      },
      dispatch
    )
)(Map);