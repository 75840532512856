import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
} from "@material-ui/core";
import { history } from '../redux';
import { StyledButton } from "../util/StyledComponents";


const useStyles = makeStyles((theme) => ({
    root: () => ({
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        margin: "10vh"
        // height: '50vh',
        // overflowY: 'scroll'
    }),
    headerContainer: () => ({
       display: 'flex',
       flexDirection: "row",
       justifyContent: "center",
       alignItems: "center",
    //    marginTop: '10vh',
       width: '100vw',
       flexWrap: 'wrap'
    }),
    imageContainer: () => ({
        maxWidth: '18rem',
        minHeight: '18rem',
        borderRadius: '50%',
        marginRight: '1vw',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }),
    image: () => ({
        display: "flex",
        justifyContent: "center",
        width: "90%",
        height: '90%',
        flexWrap: "wrap",
        // maxWidth: "400px", /* whatever you desire */
        marginLeft: "auto", /* center the container */
        marginRight: "auto", /* center the container */
    }),
    headerTextContainer: () =>({
        fontFamily: 'Bakbak One',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
    }),
    button: ({ }) => ({
        backgroundColor: '#FFCD0A',
        borderRadius: "0.75em",
        padding: '1rem 2rem 1rem 2rem',
        boxShadow: `2px 2px 2px ${theme.palette.secondary.main}`,
            '&:hover': {
                backgroundColor: "#e8b95f",
            }
    }),
    buttonText: ({ }) => ({
        fontSize: 24,
        fontFamily: 'Kanit',
    })
}));


export default function MainPage() {
    const styleConstants = {
        textBoxWidth: "90%",
    }
    const classes = useStyles(styleConstants);
    return (
        <div className={classes.root}>

            <div className={classes.headerContainer}>
                <div className={classes.imageContainer}>
                    <img alt="MagLab logo" className={classes.image} src="/magLabPhoto.png"></img>
                </div>

                <div className={classes.headerTextContainer}>
                   <div style={{fontSize: '3.5rem'}}>GROUND</div>
                   <div style={{fontSize: '4rem', marginTop: '-2.5rem'}}>MAG</div>
                   <StyledButton className={classes.button} onClick={() => history.push("/maps")}>
                        <Typography variant="body1" className={classes.buttonText}>
                            Observe Data
                        </Typography>
                    </StyledButton>
                </div>

            </div>

            {/* <MissionPage/> */}
    </div>
    )
}
